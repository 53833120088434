import React, { Component } from 'react';
import { connect } from 'react-redux';
import PostApi from '../../Logicas/PostApi';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import DownloadPostData from '../_shared/DownloadPostData/DownloadPostData';
import { BodyTable } from '../utils/BodyTable';
import IcoLink from '../utils/img/ico-external.png';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import UserApi from '../../Logicas/UserApi';
import ComponentHeader from '../utils/ComponenteHeader';
import { browserHistory } from 'react-router';
import Chip from 'material-ui/Chip';
import moment from 'moment-timezone';
import Pagination from '../utils/AutoPagination/Pagination';
import { FooterPagination } from '../utils/Pagination';
import Loader from '../utils/AutoPagination/Loader';
import not_img from '../utils/img/ico-external.png';
import { style } from '../../style';

class PostListDraft extends Component {
  constructor() {
    super();
  }

  componentWillMount() {
    !this.props.list.publications && this.props.getDraftPublication();
    if (sessionStorage.getItem('@Noz-hasMoodSurvey') === null)
      this.props.getMoodSurveyFromProfileAPI();
    else
      this.props.setMoodSurveyFromState(
        sessionStorage.getItem('@Noz-hasMoodSurvey') === 'true' ? true : false
      );
  }
  redirectNewPost() {
    browserHistory.push('/post/create');
  }

  render() {
    const { listRequestsActive } = this.props;
    if (this.props.list) {
      return (
        <div>
          <ComponentHeader
            onClick={this.redirectNewPost}
            tooltip={
              <p>
                Atualizar publicação
                <br />
              </p>
            }
            actions={
              this.props.hasMoodSurveyActive
                ? [
                    {
                      name: 'Enquetes',
                      action: this.props.getSurveyPublication
                    },
                    {
                      name: 'Formulários',
                      action: () => this.props.getPostList('form')
                    },
                    {
                      name: 'Publicações',
                      action: () => this.props.getPostList('publication')
                    },
                    {
                      name: 'Sorteios',
                      action: () => this.props.getPostList('draw', 'raffle')
                    },
                    {
                      name: 'Termômetro de humor',
                      action: () => this.props.getMoodSurveys(),
                      style: { width: '11rem', textAlign: 'center' }
                    }
                  ]
                : [
                    {
                      name: 'Enquetes',
                      action: this.props.getSurveyPublication
                    },
                    {
                      name: 'Formulários',
                      action: () => this.props.getPostList('form')
                    },
                    {
                      name: 'Publicações',
                      action: () => this.props.getPostList('publication')
                    },
                    {
                      name: 'Sorteios',
                      action: () => this.props.getPostList('draw', 'raffle')
                    }
                  ]
            }
            name="Rascunhos"
            nameaction="Nova publicação"
            withMoodSurvey
            enablesubmit={listRequestsActive.length > 0}
          />
          <DownloadPostData kind="draft" />
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>Id</TableHeadRow>
                <TableHeadRow></TableHeadRow>
                <TableHeadRow>Publicação</TableHeadRow>
                <TableHeadRow>Tags</TableHeadRow>
                <TableHeadRow>Curtidas</TableHeadRow>
                <TableHeadRow>Visualizações</TableHeadRow>
                <TableHeadRow>Data</TableHeadRow>
                <TableHeadRow>Ações</TableHeadRow>
              </TableHead>

              <TableBody>
                {this.props.list.publications !== undefined
                  ? this.props.list.publications.map(
                      function(publication, i) {
                        return (
                          <TableRow key={i} onClik={this.props.editUser}>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '30px'
                                }}
                              >
                                {publication.id}
                              </div>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '10px'
                                }}
                              >
                                {publication.cover &&
                                publication.cover.kind === 'image' ? (
                                  publication.cover.url ? (
                                    <img
                                      src={publication.cover.url}
                                      style={{
                                        width: '100px',
                                        height: '60px',
                                        'object-fit': 'cover'
                                      }}
                                      alt="conteúdo de imagem"
                                    />
                                  ) : (
                                    undefined
                                  )
                                ) : (
                                  undefined
                                )}

                                {publication.kind === 'text' ? (
                                  <img
                                    src={IcoLink}
                                    style={{
                                      width: '100px',
                                      height: '60px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="ícone imagem"
                                  />
                                ) : (
                                  undefined
                                )}

                                {publication.kind === 'link' ? (
                                  <img
                                    src={IcoLink}
                                    style={{
                                      width: '100px',
                                      height: '60px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="ícone imagem"
                                  />
                                ) : (
                                  undefined
                                )}

                                {publication.cover &&
                                publication.cover.kind === 'video' ? (
                                  publication.cover.url ? (
                                    <video
                                      src={publication.cover.url}
                                      style={{
                                        width: '100px',
                                        height: '60px',
                                        'object-fit': 'cover'
                                      }}
                                      width="100%"
                                      preload
                                      loop
                                      muted
                                      controls
                                      controlsList="nodownload"
                                      alt="conteúdo de vídeo"
                                    />
                                  ) : (
                                    undefined
                                  )
                                ) : (
                                  undefined
                                )}

                                {publication.cover &&
                                publication.cover.kind === 'carousel' ? (
                                  publication.cover.url ? (
                                    <img
                                      src={publication.cover.url}
                                      style={{
                                        width: '100px',
                                        height: '60px',
                                        'object-fit': 'cover'
                                      }}
                                      alt="conteúdo de imagem"
                                    />
                                  ) : (
                                    undefined
                                  )
                                ) : (
                                  undefined
                                )}

                                {publication.cover === null && (
                                  <img
                                    src={not_img}
                                    style={{
                                      width: '100px',
                                      height: '60px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="conteúdo de imagem"
                                  />
                                )}
                              </div>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '30px'
                                }}
                              >
                                {publication.title &&
                                publication.title.length > 30
                                  ? publication.title.slice(0, 30) + '...'
                                  : publication.title}
                              </div>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '15px'
                                }}
                              >
                                <Chip> {publication.tag_main}</Chip>
                              </div>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '30px'
                                }}
                              >
                                {publication.likes}
                              </div>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '30px'
                                }}
                              >
                                {publication.visualization}
                              </div>
                            </TableData>

                            <TableData>
                              <div
                                style={{
                                  marginTop: '30px'
                                }}
                              >
                                {publication.publish_in
                                  ? `Agendada para ${moment
                                      .tz(
                                        publication.publish_in,
                                        'Europe/Berlin'
                                      )
                                      .format('DD/MM/YYYY')}`
                                  : 'Aguardando publicação'}
                              </div>
                              <br />
                              <label>{publication.user_name}</label>
                            </TableData>
                            <TableData>
                              <div
                                style={{
                                  marginTop: '20px'
                                }}
                              >
                                <RemoveEdit
                                  Delete={this.props.deleteShowDialogPublication.bind(
                                    this,
                                    publication.id,
                                    publication.is_publication
                                  )}
                                  Edit={this.props.editPost.bind(
                                    this,
                                    publication.id,
                                    publication.is_publication
                                  )}
                                  enablesubmit={listRequestsActive.length > 0}
                                />
                              </div>
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                  : undefined}
              </TableBody>
            </Table>

            {this.props.list.size > 0 && (
              <footer className="footer-pagination">
                <FooterPagination
                  activePage={this.props.page}
                  totalItemsCount={this.props.list.size}
                  handlePageChange={(pageNumber) =>
                    this.props.getPage(pageNumber)
                  }
                />
              </footer>
            )}
          </BodyTable>
          <Loader loading={this.props.loading} />
          <DialogYesNo
            yesclick={this.props.deletePost.bind(
              this,
              this.props.alert.id_elemente,
              this.props.alert.is_publication
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover esta publicação ?"
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.post.list,
    page: state.post.page,
    alert: state.dialog,
    pagination: state.post.pagination,
    listRequestsActive: state.post.listRequestsActive,
    loading: state.progress_indeterminate,
    hasMoodSurveyActive: state.user.hasMoodSurveyActive
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPage: (page) => {
      dispatch(PostApi.getPostPageDraft(page));
    },
    getPostList: (kind, raffle = '') => {
      dispatch(PostApi.getPostListV2(kind, raffle));
    },
    deleteShowDialogPublication: (id, values) => {
      dispatch(DialogApi.deleteAlert(id));
    },
    editPost: (id) => {
      dispatch(PostApi.editPost(id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deletePost: (id, values) => {
      dispatch(PostApi.deletePost(id, values, true));
    },
    getDraftPublication: () => {
      dispatch(PostApi.getPostListDraft());
    },
    getSurveyPublication: () => {
      dispatch(PostApi.getSurveyList(1));
    },
    getMoodSurveys: (page = 1, active) => {
      dispatch(PostApi.getMoodSurveys(page, active));
    },
    getMoodSurveyFromProfileAPI: () => {
      dispatch(UserApi.getMoodSurveyFromProfileAPI());
    },
    setMoodSurveyFromState: (hasMoodSurveyActive) => {
      dispatch({
        type: 'SET_MOOD_SURVEY_ACTIVE',
        hasMoodSurveyActive
      });
    }
  };
};
const PostListDraftContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostListDraft);

export default PostListDraftContainer;
