export const teamsAppDiscovery = {
  'Pintou Parceria Suvinil': [
    { id_sector: 1, name_sector: 'Treinamentos' },
    { id_sector: 2, name_sector: 'Congressos e eventos' },
    { id_sector: 3, name_sector: 'Site suvinil.com.br' },
    { id_sector: 4, name_sector: 'Indicação de vendedores' },
    { id_sector: 5, name_sector: 'Redes sociais' },
    { id_sector: 6, name_sector: 'Indicação de outros profissionais' },
    { id_sector: 7, name_sector: 'Outros' }
  ]
};
