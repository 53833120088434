import { style as teamAttributes } from '../../../style';
import { sampleSettings, teamSettings } from './Clients/teamSettings';

const reCaptchaKey = {
  V1: '6LchgG0aAAAAAMAS4A9ZQEtd_p7n2S5A5d4shOh8',
  V2: '6LdvG08qAAAAADLimyIeoYbC4Y1-sGhfIRU_hLJx'
};

const getTeamSettings = () => {
  const {
    teamName: { name: teamName }
  } = teamAttributes;

  let defaultSettings = sampleSettings.settings;

  const buildTeam = teamSettings.find((team) => teamName === team.teamName);

  if (buildTeam) {
    defaultSettings = { ...defaultSettings, ...buildTeam.settings };
  }

  const {
    hasCourses,
    hasEvents,
    hasTools,
    hasStore,
    hasHistorical,
    hasGenderFilter,
    hasRecaptcha,
    hasExternalUsers,
    hasApplications,
    hasUsersEngagement,
    displayGenderField,
    userFilter,
    userTypes,
    hasBrands,
    hasAcademyRole
  } = defaultSettings;

  return {
    hasCourses,
    hasEvents,
    hasTools,
    hasStore,
    hasHistorical,
    hasGenderFilter,
    hasRecaptcha,
    hasExternalUsers,
    hasApplications,
    hasUsersEngagement,
    displayGenderField,
    userFilter,
    userTypes,
    hasBrands,
    hasAcademyRole,
    reCaptchaKey: reCaptchaKey[teamAttributes.variables.recaptchaVersion]
  };
};

export default getTeamSettings;
