export function post_reactions(
  state = { data: { canvas_likes: [], pagination: {} } },
  action
) {
  switch (action.type) {
    case 'GET_REACTIONS':
      if (!action.reset) {
        if (action.data.canvas_likes && action.data.canvas_likes.length >= 1) {
          return {
            ...state,
            data: {
              canvas_likes: [
                ...state.data.canvas_likes,
                ...action.data.canvas_likes
              ],
              pagination: action.data.pagination
            },
            id_post: action.post_id,
            visible: action.visible
          };
        } else {
          return {
            ...state,
            data: {
              canvas_likes: [],
              pagination: {}
            },
            id_post: action.post_id,
            visible: action.visible
          };
        }
      } else {
        return {
          ...state,
          data: {
            canvas_likes: action.data.canvas_likes || [],
            pagination: action.data.pagination || {}
          },
          id_post: action.post_id,
          visible: action.visible
        };
      }

    default:
      return state;
  }
}
