import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { Router, Route, browserHistory } from 'react-router';
import ForgetPassword from './componentes/login/forgetpassword';
import Home from './componentes/login/Home';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import tagReducer from './reducers/tagReducer';
import { sectorReducer } from './reducers/sector';
import userReducer from './reducers/userReducer';
import { dragdrop } from './reducers/body';
import { pagination } from './reducers/pagination';
import { dialog } from './reducers/dialog';
import dashboardReducer from './reducers/dashboardReducer';
import { post } from './reducers/post';
import { course } from './reducers/course';
import { repositories } from './reducers/repository';
import { module_reducer } from './reducers/module';
import { storeList } from './reducers/store';
import { loadingProducts } from './reducers/store_loading';
import { market } from './reducers/market_store';
import { erroalert } from './reducers/erroalert';
import { tagsparsed } from './reducers/tagsparsed';
import { tagsWithIds } from './reducers/tagsWithIds';
import { tagsWithPagination } from './reducers/tagsWithPagination';
import { dialogprogress } from './reducers/dialogprogress';
import { canvas } from './reducers/canvas';
import eventsReducer from './reducers/eventsReducer';
import { createpost } from './reducers/creatpost';
import { loadstatus } from './reducers/load_status';
import { content } from './reducers/content';
import { editpost } from './reducers/editpost';
import { dash_bord_component } from './reducers/dash_board_componente';
import { editpostform } from './reducers/editpostform';
import { dialog_dispatch } from './reducers/dialog_dispatch';
import { canvas_links } from './reducers/canvas_link';
import { show_modal_birthday } from './reducers/show_modal_birthday';
import { dialoglink } from './reducers/dialoglink';
import { edit_link } from './reducers/editlink';
import { dialogeditmidia } from './reducers/dialogeditmidia';
import { edit_old_publication } from './reducers/edit_old_publication';
import { progress_indeterminate } from './reducers/progress_indetermindate';
import { load_status_delete } from './reducers/load_status_delete';
import { alert_erro_geral } from './reducers/alert_erro_geral';
import { notification_list } from './reducers/notification_list';
import { dialog_score } from './reducers/dialog_score';
import { dialog_push } from './reducers/dialog_create_notification';
import { post_likes } from './reducers/post_likes';
import { post_views } from './reducers/post_views';
import { get_activities } from './reducers/get_activities';
import { get_app_discovery } from './reducers/get_app_discovery';
import { check_all_users } from './reducers/check_all_users';
import { statusimport } from './reducers/status_import';
import { alert_term_use } from './reducers/alert_term_use';
import { post_comments } from './reducers/post_comments';
import { dialog_report } from './reducers/dialog_report';
import { dropfile } from './reducers/dropfile';
import { show_modal_insert_users_tag } from './reducers/show_modal_insert_users_tag';
import { reducer as formReducer } from 'redux-form';
import { submitting } from './reducers/form_submiting';
import { enable_form_after_callback } from './reducers/enable_form_after_callback';
import { load_content_requisition } from './reducers/load_content_requisition';
import { isLoadingtags } from './reducers/is_loading_tags';
import { gallery } from './reducers/gallery';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { dialog_gallery } from './reducers/dialog_gallery';
import { progress_upload } from './reducers/progress_upload';
import { load_progress_dialog } from './reducers/load_progress_dialog';
import { survery } from './reducers/survery';
import { dialog_notification_users } from './reducers/dialog_notification_users';
import { getHeaderCourseDetails } from './reducers/getHeaderCourseDetails';
import { application } from './reducers/application';
import { getTypeForm } from './reducers/get_type_form';
import { getHeaderFormDetails } from './reducers/getHeaderFormDetails';
import { form } from './reducers/form';
import { getHeaderFileSend } from './reducers/getHeaderFileSend';
import drawReducer from './reducers/drawReducer';
import { token_resend } from './reducers/token_resend';
import { historical_points } from './reducers/historical_points';
import { progress_course_loading } from './reducers/progress_course_loading';
import { requestError } from './reducers/requestError';
import { sectionReducer } from './reducers/sectionReducer';
import { menuReducer } from './reducers/menuReducer';
import specialPostDialogReducer from './reducers/specialPostDialogReducer';
import requestReducer from './reducers/requestReducer';
import { dialog_report_email } from './reducers/dialog_report_email';
import { dialog_pontuation_users } from './reducers/dialog_pontuation_users';
import { dialog_resend_welcome } from './reducers/dialog_resend_welcome';
import { dialog_historical_email } from './reducers/dialog_historical_email';
import { dialog_user_address } from './reducers/dialog_user_address';
import { dialog_spreadsheet_tag_assignment } from './reducers/dialog_spreadsheet_tag_assignment';
import { post_reactions } from './reducers/post_reactions';

const reducers = combineReducers({
  tag: tagReducer,
  form: formReducer,
  sectorReducer: sectorReducer,
  show_modal_insert_users_tag: show_modal_insert_users_tag,
  user: userReducer,
  dialog: dialog,
  eventsReducer: eventsReducer,
  pagination: pagination,
  dashboard: dashboardReducer,
  notification_list: notification_list,
  post: post,
  dragdrop: dragdrop,
  course: course,
  module: module_reducer,
  content: content,
  storeList: storeList,
  loadingProducts: loadingProducts,
  market: market,
  repositories: repositories,
  erroalert: erroalert,
  tagsparsed: tagsparsed,
  tagsWithIds: tagsWithIds,
  tagsWithPagination: tagsWithPagination,
  dialogprogress: dialogprogress,
  canvas: canvas,
  canvas_links: canvas_links,
  createpost: createpost,
  editpost: editpost,
  loadstatus: loadstatus,
  editpostform: editpostform,
  edit_old_publication: edit_old_publication,
  edit_link: edit_link,
  dialoglink: dialoglink,
  dialogeditmidia: dialogeditmidia,
  load_status_delete: load_status_delete,
  alert_erro_geral: alert_erro_geral,
  submitting: submitting,
  progress_indeterminate: progress_indeterminate,
  dialog_score: dialog_score,
  show_modal_birthday: show_modal_birthday,
  check_all_users: check_all_users,
  alert_term_use: alert_term_use,
  dropfile: dropfile,
  statusimport: statusimport,
  post_comments: post_comments,
  dialog_report: dialog_report,
  dash_bord_component: dash_bord_component,
  dialog_dispatch: dialog_dispatch,
  dialog_push: dialog_push,
  post_likes: post_likes,
  post_reactions: post_reactions,
  post_views: post_views,
  get_activities: get_activities,
  get_app_discovery: get_app_discovery,
  load_content_requisition: load_content_requisition,
  enable_form_after_callback: enable_form_after_callback,
  isLoadingtags: isLoadingtags,
  gallery: gallery,
  dialog_gallery: dialog_gallery,
  progress_upload: progress_upload,
  load_progress_dialog: load_progress_dialog,
  survery: survery,
  dialog_notification_users: dialog_notification_users,
  getHeaderCourseDetails: getHeaderCourseDetails,
  application: application,
  getTypeForm: getTypeForm,
  getHeaderFormDetails: getHeaderFormDetails,
  getForm: form,
  getHeaderFileSend: getHeaderFileSend,
  draw: drawReducer,
  token_resend: token_resend,
  historical_points: historical_points,
  progress_course_loading: progress_course_loading,
  requestError: requestError,
  section: sectionReducer,
  menu: menuReducer,
  specialPostDialog: specialPostDialogReducer,
  request: requestReducer,
  dialog_report_email: dialog_report_email,
  dialog_resend_welcome: dialog_resend_welcome,
  dialog_historical_email: dialog_historical_email,
  dialog_user_address: dialog_user_address,
  dialog_pontuation_users: dialog_pontuation_users,
  dialog_spreadsheet_tag_assignment: dialog_spreadsheet_tag_assignment
});

let store = undefined;
if (process.env.REACT_APP_ENV === 'development') {
  const middleWare = [thunkMiddleware];
  store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middleWare))
  );
} else {
  store = createStore(reducers, applyMiddleware(thunkMiddleware));
}

export const storeCopy = { ...store };

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route path="/" component={Home} />
      <Route path="/forget/password" component={ForgetPassword} />
      <Route path="/user/create" component={App} />
      <Route path="/user/list" component={App} />
      <Route path="/user/edit" component={App} />
      <Route path="/user/import" component={App} />
      <Route path="/user/validation" component={App} />
      <Route path="/user/customfields" component={App} />

      <Route path="/post/create" component={App} />
      <Route path="/post/canvas/contens" component={App} />
      <Route path="/post/publication" component={App} />
      <Route path="/post/edit" component={App} />
      <Route path="/post/drafts" component={App} />
      <Route path="/post/draw" component={App} />
      <Route path="/post/form" component={App} />
      <Route path="/post/form/create" component={App} />
      <Route path="/post/form/edit" component={App} />
      <Route path="/post/form/view" component={App} />
      <Route path="/post/survey" component={App} />
      <Route path="/post/survey/create" component={App} />
      <Route path="/post/survey/view" component={App} />
      <Route path="/post/moodSurveys" component={App} />

      <Route path="/course/list" component={App} />
      <Route path="/course/results" component={App} />
      <Route path="/course/edit" component={App} />
      <Route path="/course/create" component={App} />
      <Route path="/course/modules" component={App} />
      <Route path="/course/modules/create" component={App} />

      <Route path="/store/products" component={App} />
      <Route path="/store/edit" component={App} />
      <Route path="/store/create" component={App} />

      <Route path="/product/history" component={App} />

      <Route path="/module/create" component={App} />
      <Route path="/module/edit" component={App} />
      <Route path="/module/cards" component={App} />

      <Route path="/files/folders" component={App} />
      <Route path="/files/list" component={App} />
      <Route path="/file/create" component={App} />
      <Route path="/file/edit" component={App} />
      <Route path="/folder/edit" component={App} />
      <Route path="/folder/create" component={App} />

      <Route path="/tags/list" component={App} />
      <Route path="/tags/edit" component={App} />
      <Route path="/tags/merger" component={App} />
      <Route path="/edit/event" component={App} />

      <Route path="/sector/list" component={App} />

      <Route path="/calendar" component={App} />
      <Route path="/calendar/new" component={App} />

      <Route path="/notification/list" component={App} />
      <Route path="/galleries" component={App} />
      <Route path="/gallery" component={App} />

      <Route path="/home" component={App} />

      <Route path="/app/list" component={App} />
      <Route path="/app/create" component={App} />
      <Route path="/app/edit" component={App} />

      <Route path="/tools/list" component={App} />
      <Route path="/tools/file/create" component={App} />
      <Route path="/tools/file/edit" component={App} />
      <Route path="/tools/link/create" component={App} />
      <Route path="/tools/link/edit" component={App} />
      <Route path="/tools/section/create" component={App} />
      <Route path="/tools/section/edit" component={App} />

      <Route path="/items-menu" component={App} />

      <Route path="/historical/list" component={App} />
      <Route path="/historical/userDetails" component={App} />
      <Route path="/historical/publishDetails" component={App} />
    </Router>
  </Provider>,
  document.getElementById('root')
);
unregister();
