import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { browserHistory } from 'react-router';
import { style } from './style';
import DashBord from './componentes/dashbord/DashBord';
import './App.css';
import CreateUser from './componentes/user/NewUser';
import EditUser from './componentes/user/UserEdit';
import PropTypes from 'prop-types';
import UserList from './componentes/user/UserList';
import UserCustomFields from './componentes/user/components/UserCustomFields/UserCustomFields';
import HomeDash from './componentes/dashbord/dashboard_components/HomeDash';
import PostList from './componentes/publication/publicationlist';
import CanvasCreatInitial from './componentes/canvas/components/canvas publication initial/CanvasFormInitial';
import CanvasEdit from './componentes/canvas/components/forms/EditCanvasPublication';
import CourseList from './componentes/academy/course/CourseList';
import CourseResults from './componentes/academy/course/CourseResults';
import CourseModules from './componentes/academy/course/EditCourse';
import ContainerModules from './componentes/academy/course/CourseLong/ContainerModules';
import EditCourseFrom from './componentes/academy/course/EditCourseForm';
import NewModule from './componentes/academy/modules/NewModule';
import NewCourse from './componentes/academy/course/NewCourse';
import EditModuleCards from './componentes/academy/modules/ModuleCards';
import ProductList from './componentes/store/ProductList';
import EditProduct from './componentes/store/EditProduct';
import CreateProduct from './componentes/store/NewProduct';
import FileList from './componentes/repository/repositorylist';
import EditFolder from './componentes/repository/EditFolder';
import CreateFolder from './componentes/repository/NewFolder';
import ProductHistory from './componentes/store/history/ProductHistory';
import TagList from './componentes/tag/TagList';
import TagEdit from './componentes/tag/TagEditUser';
import FileListRepository from './componentes/repository/filerepositorylist';
import TagMerge from './componentes/tag/TagMerge';
import FileCreate from './componentes/repository/NewFile';
import EditModule from './componentes/academy/modules/EditModule';
import EditFile from './componentes/repository/EditFile';
import PostListDraft from './componentes/publication/PostListDraft';
import NotificationList from './componentes/notification/NotificationList';
import Calendar from './componentes/calendar/Calendar';
import ImportUsers from './componentes/import_user/Index';
import EventList from './componentes/calendar/EventList';
import GalleryList from './componentes/gallery/GalleryList/GalleryList';
import GalleryListItems from './componentes/gallery/GaleryDisplay/GalleryDisplay';
import Survey from './componentes/survey/Survey';
import SurveyCreate from './componentes/survey/SurveyCreate';
import SurveyView from './componentes/survey/SurveyView';
import MoodSurveys from './componentes/MoodSurveys/MoodSurveys';
import Draw from './componentes/draw/Draw';
import FormList from './componentes/Form/FormList';
import ViewForm from './componentes/Form/ViewForm';
import ApplicationList from './componentes/Application/ApplicationList';
import CreateApplication from './componentes/Application/CreateApplication';
import EditApplication from './componentes/Application/EditApplication';
import ToolsList from './componentes/Tools/ToolsList/ToolsList';
import ToolsFileCreate from './componentes/Tools/File/FileCreate/FileCreate';
import ToolsFileEdit from './componentes/Tools/File/FileEdit/FileEdit';
import ToolsLinkCreate from './componentes/Tools/Link/LinkCreate/LinkCreate';
import ToolsLinkEdit from './componentes/Tools/Link/LinkEdit/LinkEdit';
import ToolsSectionCreate from './componentes/Tools/Section/SectionCreate/SectionCreate';
import ToolsSectionEdit from './componentes/Tools/Section/SectionEdit/SectionEdit';
import Menu from './componentes/Menu/Menu';
import EditPost from './componentes/CreateOrEditPost/EditPost';
import CreatePost from './componentes/CreateOrEditPost/CreatePost';
import SectorList from './componentes/Sector/SectorList';
import HistoryList from './componentes/HistoryPoint/HistoryList';
import UserDetails from './componentes/HistoryPoint/UserDetails';
import PublishDetails from './componentes/HistoryPoint/PublishDetails';
import { DialogYesNo } from './componentes/utils/DailogComponentes';
import teamSettings from './componentes/utils/TeamsSettings/generalSettings';

const defaultTimeOutTime = 60 * 1000 * 20;
const modalWarningTimeOut = 60 * 1000 * 1;

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      timeOut: defaultTimeOutTime,
      isTimedOut: false,
      showModal: false,
      hasApplications: false,
      hasTools: false
    };

    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.cancelLogOut = this.cancelLogOut.bind(this);
    this.logOutTrue = this.logOutTrue.bind(this);
  }

  onAction() {
    this.setState({
      isTimedOut: false,
      timeOut: defaultTimeOutTime
    });
    this.idleTimer.reset();
  }

  onIdle() {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      localStorage.clear();
      browserHistory.push('/');
    } else {
      this.setState({ showModal: true });
      this.setState({ timeOut: modalWarningTimeOut });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  cancelLogOut() {
    this.setState({ showModal: false });
  }
  logOutTrue() {
    localStorage.clear();
    browserHistory.push('/');
  }

  componentWillMount() {
    const { hasApplications, hasTools } = teamSettings();

    this.context.store.subscribe(() => {
      this.setState({
        user: this.context.store.getState().user.userStorage,
        hasApplications,
        hasTools
      });
    });
  }

  render() {
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let user_roles = roles.map(function(role) {
      return role.name;
    });
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeOut}
        />
        <div>
          <DashBord route={this.props.location.pathname} />

          {this.props.location.pathname === '/user/create' &&
          (user_roles.includes('admin') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager') ||
            user_roles.includes('editor')) ? (
            <CreateUser store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/user/list' &&
          (user_roles.includes('admin') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager') ||
            user_roles.includes('editor')) ? (
            <UserList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/user/edit' &&
          (user_roles.includes('admin') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager') ||
            user_roles.includes('editor')) ? (
            <EditUser store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/user/customfields' &&
          (user_roles.includes('admin') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager')) ? (
            <UserCustomFields store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/home' &&
          (user_roles.includes('admin') || user_roles.includes('manager')) ? (
            <HomeDash store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/publication' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <PostList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/edit' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            // <CanvasEdit store={this.context.store} />
            <EditPost store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/create' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            // <CanvasCreatInitial store={this.context.store} />
            <CreatePost store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/form' &&
            (user_roles.includes('admin') ||
              user_roles.includes('speaker') ||
              user_roles.includes('manager')) && (
              <FormList store={this.context.store} />
            )}
          {this.props.location.pathname === '/post/form/create' &&
            (user_roles.includes('admin') ||
              user_roles.includes('speaker') ||
              user_roles.includes('manager')) && (
              // <CanvasEdit store={this.context.store} />
              <EditPost store={this.context.store} />
            )}
          {this.props.location.pathname === '/post/form/edit' &&
            (user_roles.includes('admin') ||
              user_roles.includes('speaker') ||
              user_roles.includes('manager')) && (
              // <CanvasEdit store={this.context.store} />
              <EditPost store={this.context.store} />
            )}
          {this.props.location.pathname === '/post/form/view' &&
            (user_roles.includes('admin') ||
              user_roles.includes('speaker') ||
              user_roles.includes('manager')) && (
              <ViewForm store={this.context.store} />
            )}
          {this.props.location.pathname === '/post/draw' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <Draw store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/course/list' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <CourseList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/course/results' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <CourseResults store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/course/create' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <NewCourse store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/course/modules' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <CourseModules store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/course/modules/create' &&
            (user_roles.includes('admin') ||
              user_roles.includes('academy') ||
              user_roles.includes('manager')) && (
              <ContainerModules store={this.context.store} />
            )}
          {this.props.location.pathname === '/course/edit' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <EditCourseFrom store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/module/create' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <NewModule store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/module/cards' &&
          (user_roles.includes('admin') ||
            user_roles.includes('academy') ||
            user_roles.includes('manager')) ? (
            <EditModuleCards store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/store/products' &&
          (user_roles.includes('admin') || user_roles.includes('store')) ? (
            <ProductList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/store/edit' &&
          (user_roles.includes('admin') || user_roles.includes('store')) ? (
            <EditProduct store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/store/create' &&
          (user_roles.includes('admin') || user_roles.includes('store')) ? (
            <CreateProduct store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/files/folders' &&
          user_roles.includes('admin') ? (
            <FileList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/files/list' &&
          user_roles.includes('admin') ? (
            <FileListRepository store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/folder/edit' &&
          user_roles.includes('admin') ? (
            <EditFolder store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/folder/create' &&
          user_roles.includes('admin') ? (
            <CreateFolder store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/product/history' &&
          (user_roles.includes('admin') || user_roles.includes('store')) ? (
            <ProductHistory store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/tags/list' &&
          (user_roles.includes('admin') || user_roles.includes('manager')) ? (
            <TagList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/tags/edit' &&
          (user_roles.includes('admin') || user_roles.includes('manager')) ? (
            <TagEdit store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/tags/merger' &&
          (user_roles.includes('admin') || user_roles.includes('manager')) ? (
            <TagMerge store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/sector/list' &&
            user_roles.includes('admin') && (
              <SectorList store={this.context.store} />
            )}
          {this.props.location.pathname === '/file/create' &&
          user_roles.includes('admin') ? (
            <FileCreate store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/module/edit' &&
          (user_roles.includes('admin') || user_roles.includes('academy')) ? (
            <EditModule store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/file/edit' &&
          user_roles.includes('admin') ? (
            <EditFile store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/drafts' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <PostListDraft store={this.context.store} />
          ) : (
            undefined
          )}

          {this.props.location.pathname === '/calendar/new' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager')) ? (
            <Calendar store={this.context.store} />
          ) : (
            undefined
          )}

          {this.props.location.pathname === '/user/import' &&
          user_roles.includes('admin') ? (
            <ImportUsers store={this.context.store} />
          ) : (
            undefined
          )}

          {this.props.location.pathname === '/calendar' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager')) ? (
            <EventList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/notification/list' &&
          user_roles.includes('admin') ? (
            <NotificationList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/galleries' &&
          user_roles.includes('admin') ? (
            <GalleryList store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/gallery' &&
          user_roles.includes('admin') ? (
            <GalleryListItems store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/survey' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <Survey store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/survey/create' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <SurveyCreate store={this.context.store} />
          ) : (
            undefined
          )}
          {this.props.location.pathname === '/post/survey/view' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <SurveyView store={this.context.store} />
          ) : (
            undefined
          )}

          {this.props.location.pathname === '/post/moodSurveys' &&
          (user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager')) ? (
            <MoodSurveys store={this.context.store} />
          ) : (
            undefined
          )}
          {this.state.hasApplications ? (
            this.props.location.pathname === '/app/list' &&
            user_roles.includes('admin') ? (
              <ApplicationList store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasApplications ? (
            this.props.location.pathname === '/app/create' &&
            user_roles.includes('admin') ? (
              <CreateApplication store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasApplications ? (
            this.props.location.pathname === '/app/edit' &&
            user_roles.includes('admin') ? (
              <EditApplication store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/list' &&
            user_roles.includes('admin') ? (
              <ToolsList store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/file/create' &&
            user_roles.includes('admin') ? (
              <ToolsFileCreate store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/file/edit' &&
            user_roles.includes('admin') ? (
              <ToolsFileEdit store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/link/create' &&
            user_roles.includes('admin') ? (
              <ToolsLinkCreate store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/link/edit' &&
            user_roles.includes('admin') ? (
              <ToolsLinkEdit store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/section/create' &&
            user_roles.includes('admin') ? (
              <ToolsSectionCreate store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.state.hasTools ? (
            this.props.location.pathname === '/tools/section/edit' &&
            user_roles.includes('admin') ? (
              <ToolsSectionEdit store={this.context.store} />
            ) : (
              undefined
            )
          ) : null}
          {this.props.location.pathname === '/historical/list' &&
            (user_roles.includes('admin') ||
              user_roles.includes('manager')) && (
              <HistoryList store={this.context.store} />
            )}
          {this.props.location.pathname === '/historical/userDetails' &&
            (user_roles.includes('admin') ||
              user_roles.includes('manager')) && (
              <UserDetails store={this.context.store} />
            )}
          {this.props.location.pathname === '/historical/publishDetails' &&
            (user_roles.includes('admin') ||
              user_roles.includes('manager')) && (
              <PublishDetails store={this.context.store} />
            )}

          {this.props.location.pathname === '/items-menu' &&
          (user_roles.includes('admin') || user_roles.includes('speaker')) ? (
            <Menu store={this.context.store} />
          ) : (
            undefined
          )}
        </div>
        <DialogYesNo
          noclick={this.cancelLogOut}
          yesclick={this.logOutTrue}
          message="Você ficou inativo(a) por 20 minutos e será deslogado(a). Tudo bem?"
          visible={this.state.showModal}
        />
      </>
    );
  }
}

App.contextTypes = {
  store: PropTypes.object.isRequired
};

export default App;
