import { style } from '../style';

export function get_app_discovery(state = { data: [] }, action) {
  if (action.type === 'GET_APP_DISCOVERY') {
    if (!action.reset) {
      if (action.data.length >= 1) {
        action.data.map(function(activities) {
          state.data.push(activities);
        });
      } else {
        state.data = [];
      }
    } else {
      state.data = action.data;
    }

    return {
      data: state.data
    };
  }
  return state;
}
